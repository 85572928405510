define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0-2a.5.5 0 110 1 .5.5 0 010-1z\" fill=\"#737475\"/><path d=\"M13 2H3a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V3a1 1 0 00-1-1zm0 11H3v-3l2.5-2.5 2.795 2.795a1 1 0 001.41 0L10.5 9.5 13 12v1zm0-2.415L11.205 8.79a1 1 0 00-1.41 0L9 9.585 6.205 6.79a1 1 0 00-1.41 0L3 8.585V3h10v7.585z\" fill=\"#737475\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});