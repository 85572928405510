define("ember-svg-jar/inlined/download-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 15A7 7 0 118 1a7 7 0 010 14zM2.167 8a5.833 5.833 0 1011.666 0A5.833 5.833 0 002.167 8zm8.337-.412l-1.92 1.92V4.5H7.416v5.008l-1.921-1.92-.825.824 2.917 2.917.412.412.412-.412 2.917-2.917-.825-.824z\" fill=\"#4482E1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});