define("@embroider/macros/runtime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.config = config;
  _exports.each = each;
  _exports.getGlobalConfig = getGlobalConfig;
  _exports.isTesting = isTesting;
  _exports.macroCondition = macroCondition;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  /*
    These are the runtime implementations for the javascript macros that have
    runtime implementations.
  
    Not every macro has a runtime implementation, some only make sense in the
    build and always run there.
  
    Even when we have runtime implementations, we are still careful to emit static
    errors during the build wherever possible, and runtime errors when necessary,
    so that you're not surprised when you switch from runtime-mode to compile-time
    mode.
  */

  /*
    CAUTION: in classic builds, this file gets shared by all present copies of
    @embroider/macros. If you want to change its public API, you need to rename it
    and update `pathToRuntime` in ../babel/state.ts to point at it, so that your
    babel plugin and runtime will match.
  */

  function each(array) {
    if (!Array.isArray(array)) {
      throw new Error("the argument to the each() macro must be an array");
    }
    return array;
  }
  function macroCondition(predicate) {
    return predicate;
  }

  // This is here as a compile target for `getConfig` and `getOwnConfig` when
  // we're in runtime mode. This is not public API to call from your own code.
  function config(packageRoot) {
    return runtimeConfig.packages[packageRoot];
  }
  function getGlobalConfig() {
    return runtimeConfig.global;
  }
  function isTesting() {
    var g = runtimeConfig.global;
    var e = g && g['@embroider/macros'];
    return Boolean(e && e.isTesting);
  }
  var runtimeConfig = initializeRuntimeMacrosConfig();

  // this exists to be targeted by our babel plugin
  function initializeRuntimeMacrosConfig() {
    return {
      "packages": {
        "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/ember-get-config@2.1.1/node_modules/ember-get-config": {
          "modulePrefix": "@opswiz/frontend"
        },
        "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/ember-bootstrap@6.4.2_@babel+core@7.26.0_@ember+string@4.0.0_@glimmer+component@1.1.2_@_68aa9723c8a066dea86aed86edb25d7d/node_modules/ember-bootstrap": {
          "isBS4": true,
          "isBS5": false,
          "isNotBS5": true,
          "version": "6.4.2"
        },
        "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/ember-froala-editor@4.4.0_ember-source@5.12.0_webpack@5.97.1/node_modules/ember-froala-editor": {
          "languages": ["es.js"],
          "plugins": {
            "css": ["char_counter.min.css", "table.min.css", "colors.min.css"],
            "js": ["align.min.js", "char_counter.min.js", "paragraph_format.min.js", "table.min.js", "inline_style.min.js", "colors.min.js", "font_family.min.js"]
          },
          "third_party": {
            "css": [],
            "js": []
          },
          "themes": ["royal.min.css"]
        }
      },
      "global": {
        "@embroider/macros": {
          "isTesting": false
        },
        "WarpDrive": {
          "debug": {
            "LOG_GRAPH": false,
            "LOG_IDENTIFIERS": false,
            "LOG_INSTANCE_CACHE": false,
            "LOG_MUTATIONS": false,
            "LOG_NOTIFICATIONS": false,
            "LOG_OPERATIONS": false,
            "LOG_PAYLOADS": false,
            "LOG_REQUESTS": false,
            "LOG_REQUEST_STATUS": false
          },
          "polyfillUUID": false,
          "includeDataAdapter": true,
          "compatWith": null,
          "deprecations": {
            "DEPRECATE_CATCH_ALL": true,
            "DEPRECATE_COMPUTED_CHAINS": true,
            "DEPRECATE_EMBER_INFLECTOR": true,
            "DEPRECATE_LEGACY_IMPORTS": true,
            "DEPRECATE_MANY_ARRAY_DUPLICATES": true,
            "DEPRECATE_NON_STRICT_ID": true,
            "DEPRECATE_NON_STRICT_TYPES": true,
            "DEPRECATE_NON_UNIQUE_PAYLOADS": true,
            "DEPRECATE_RELATIONSHIP_REMOTE_UPDATE_CLEARING_LOCAL_STATE": true,
            "DEPRECATE_STORE_EXTENDS_EMBER_OBJECT": true,
            "ENABLE_LEGACY_SCHEMA_SERVICE": true
          },
          "features": {
            "SAMPLE_FEATURE_FLAG": false
          },
          "env": {
            "TESTING": true,
            "PRODUCTION": false,
            "DEBUG": true,
            "IS_RECORDING": false,
            "IS_CI": true,
            "SHOULD_RECORD": false
          }
        }
      }
    };
  }
  function updaterMethods() {
    return {
      config: config,
      getGlobalConfig: getGlobalConfig,
      setConfig: function setConfig(packageRoot, value) {
        runtimeConfig.packages[packageRoot] = value;
      },
      setGlobalConfig: function setGlobalConfig(key, value) {
        runtimeConfig.global[key] = value;
      }
    };
  }

  // this is how runtime config can get injected at boot. I'm not sure yet if this
  // should be public API, but we certainly need it internally to set things like
  // the global fastboot.isRunning.
  //
  // consumers of this API push a function onto
  // window._embroider_macros_runtime_config. The function is given four methods
  // which allow it to read and write the per-package and global configs. The
  // reason for allowing both read & write is that merging strategies are up to
  // each consumers -- read first, then merge, then write.
  //
  // For an example user of this API, see where we generate
  // embroider_macros_fastboot_init.js' in @embroider/core.
  var updaters = typeof window !== 'undefined' ? window._embroider_macros_runtime_config : undefined;
  if (updaters) {
    var methods = updaterMethods();
    var _iterator = _createForOfIteratorHelper(updaters),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var updater = _step.value;
        updater(methods);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
});