define("ember-svg-jar/inlined/arrow-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18.001A9 9 0 0112 3z\" stroke=\"#1E1E1E\" stroke-width=\"2.016\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});