define("ember-svg-jar/inlined/notification-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.672 4v0c0-.073.008-.145.023-.214l.794.496 5.333 3.334.177.11.176-.11 5.334-3.333.8-.5a.99.99 0 01.023.217v4.333h-.667V4.732l-.51.319L8 8.273 2.842 5.051l-.51-.32v7.602h5.333V13h-5c-.549 0-1-.45-1-1 0 0 0 0 0 0l.007-8zm.993-.333H1.73A.996.996 0 012.665 3h10.667c.433 0 .804.28.942.667H2.665zm9.123 9.349l2.592-2.593.479.472-3.3 3.3-1.889-1.889.469-.468 1.177 1.178.236.235.236-.236z\" fill=\"#4482E1\" stroke=\"#4482E1\" stroke-width=\".667\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});