define("ember-svg-jar/inlined/ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.001 9.333c-.366 0-.68-.13-.941-.392A1.282 1.282 0 012.668 8c0-.367.13-.68.392-.942.26-.261.575-.391.941-.391.367 0 .68.13.942.39.261.262.392.576.392.943s-.131.68-.392.941c-.261.261-.575.392-.942.392zm4 0c-.366 0-.68-.13-.941-.392A1.282 1.282 0 016.668 8c0-.367.13-.68.392-.942.26-.261.575-.391.941-.391.367 0 .681.13.942.39.261.262.392.576.392.943s-.13.68-.392.941a1.284 1.284 0 01-.942.392zm4 0c-.366 0-.68-.13-.942-.392A1.283 1.283 0 0110.668 8c0-.367.13-.68.391-.942.262-.261.576-.391.942-.391.367 0 .681.13.942.39.261.262.392.576.392.943s-.13.68-.392.941a1.284 1.284 0 01-.942.392z\" fill=\"#4482E1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});